import React, { useState } from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import { setCustomFilters } from "../store/actions";
import { useRateHistoryForStay, usePayerById } from "../api/caseManagementApi";

export const PatientTabs = ({ tabs, stayId, payerId, insurancePlans }) => {
  const rateHistoryQuery = useRateHistoryForStay(stayId);
  const payerQuery = usePayerById(payerId);

  return (
    <div className="patient-content">
      <ul className="nav nav-tabs">
        {tabs.map((navTab) => (
          <li
            role="presentation"
            key={navTab.path}
            className={window.location.pathname === navTab.path ? "active" : ""}
          >
            <NavLink to={navTab.path} exact>
              {navTab.title}
            </NavLink>
          </li>
        ))}
      </ul>
      <Switch>
        {tabs.map((tab) => (
          <Route
            key={tab.path}
            path={tab.path}
            exact={!!tab.exact}
            component={(props) => {
              const selectedTab = tabs.find(({ path }) => path === props.match.path);
              return (
                <div className="hpanel tab-pane">
                  <div className="panel-body">
                    <CustomToolTip description={selectedTab.infoLabel} />
                    <tab.component
                      {...props}
                      isLoading={(payerId && payerQuery.isLoading) || rateHistoryQuery.isLoading}
                      payer={payerQuery.data}
                      insurancePlans={insurancePlans}
                      setCustomFilters={setCustomFilters}
                    />
                  </div>
                </div>
              );
            }}
          />
        ))}
      </Switch>
    </div>
  );
};

const CustomToolTip = ({ description }) => {
  // Displays a "More Info" line of text and a tool tip
  const [canView, setCanView] = useState(false);

  return (
    <div className="more-info-wrapper" onMouseEnter={() => setCanView(true)} onMouseLeave={() => setCanView(false)}>
      {/* more info */}
      <i className="fa fa-info" />
      <p>More Info</p>

      {
        /* custom tool tip */
        canView && (
          <div id="custom-tool-tip">
            <div>{description}</div>
            <div className="tool-tip-arrow"></div>
          </div>
        )
      }
    </div>
  );
};
